import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FaUserAlt, FaPhone, FaFax, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
// import 'react-tabs/style/react-tabs.scss';

const Call = props => {
  const data = useStaticQuery(graphql`
    query ContactQuery {
      contactJson: allContactJson {
        edges {
          node {
            id
            title
            partner
            phone
            email
            fax
            address
            addressurl
            contact_button_link
          }
        }
      }
    }
   `);
  // const contactItems = props.data.ContactQuery.edges;
  return (
    <>
      <div className="call">

        <Tabs>
          <TabList>
            <Tab>San Diego</Tab>
            <Tab>Del Mar</Tab>
          </TabList>
          {data.contactJson.edges.map(({ node }, index) => (
            <TabPanel>
              <div className="call-box-top">
                {node.partner && (
                  <div className="call-partner">
                    <FaUserAlt title="Partner" /> {node.partner}
                  </div>
                )}
                {node.phone && (
                  <div className="call-phone">
                    <FaPhone title="Phone" />
                    <a href={`tel:${node.phone}`}>
                      {node.phone}
                    </a>
                  </div>
                )}
                {node.fax && (
                  <div className="call-phone">
                    <FaFax title="Fax" />
                    <a href={`fax:${node.fax}`}>
                      {node.fax}
                    </a>
                  </div>
                )}
                {node.email && (
                  <div className="call-phone">
                    <FaEnvelope title="Email" />
                    <a href={`mailto:${node.email}`}>
                      {node.email}
                    </a>
                  </div>
                )}
                {node.address && (
                  <div className="call-address">
                    <FaMapMarkerAlt title="Address" />
                    <a href={node.addressurl}>{node.address}</a>
                  </div>
                )}
              </div>
              {props.showButton && (
                <div className="call-box-bottom">
                  <a href={node.contact_button_link} className="button">Contact</a>
                </div>
              )}
            </TabPanel>
          ))}
        </Tabs>
      </div>
    </>
  );
};

export default Call;
